import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-671773c2"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "statistics"
}
const _hoisted_2 = { class: "board" }
const _hoisted_3 = { class: "board-small" }
const _hoisted_4 = { class: "count" }
const _hoisted_5 = { class: "board-pie" }
const _hoisted_6 = { class: "board" }
const _hoisted_7 = { class: "board-small" }
const _hoisted_8 = { class: "count" }
const _hoisted_9 = { class: "board-pie" }
const _hoisted_10 = {
  key: 1,
  class: "empty-block"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_basic_card = _resolveComponent("basic-card")!
  const _component_pie_chart = _resolveComponent("pie-chart")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_line_chart = _resolveComponent("line-chart")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_bar_chart = _resolveComponent("bar-chart")!
  const _component_el_empty = _resolveComponent("el-empty")!

  return (_ctx.iterateId)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_el_row, { gutter: 10 }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, { span: 12 }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.taskCardList, (i) => {
                      return (_openBlock(), _createBlock(_component_basic_card, {
                        key: i.key,
                        title: i.title,
                        height: 125,
                        class: "board-small-card"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", _hoisted_4, _toDisplayString(i.count), 1)
                        ]),
                        _: 2
                      }, 1032, ["title"]))
                    }), 128))
                  ]),
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_component_basic_card, {
                      title: "任务状态分布图",
                      height: 400
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_pie_chart, {
                          id: "pie1",
                          type: 0,
                          data: _ctx.taskStatusPie
                        }, null, 8, ["data"])
                      ]),
                      _: 1
                    })
                  ])
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 12 }, {
              default: _withCtx(() => [
                _createVNode(_component_basic_card, {
                  title: "开发工时燃尽图",
                  height: 400,
                  tooltip: _ctx.tooltip.iterationHourBurnDownLine
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_line_chart, {
                      id: "line1",
                      type: 0,
                      data: _ctx.iterationHourBurnDownLine
                    }, null, 8, ["data"])
                  ]),
                  _: 1
                }, 8, ["tooltip"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_row, {
          gutter: 10,
          style: {"margin-top":"10px"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, { span: 12 }, {
              default: _withCtx(() => [
                _createVNode(_component_basic_card, {
                  title: "成员开发工时数",
                  height: 400
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_bar_chart, {
                      id: "bar1",
                      type: 0,
                      data: _ctx.staffHourBar
                    }, null, 8, ["data"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 12 }, {
              default: _withCtx(() => [
                _createVNode(_component_basic_card, {
                  title: "成员开发工时燃尽图",
                  height: 400,
                  tooltip: _ctx.tooltip.staffHourBurnDownLine
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_line_chart, {
                      id: "line2",
                      type: 1,
                      data: _ctx.staffHourBurnDownLine
                    }, null, 8, ["data"])
                  ]),
                  _: 1
                }, 8, ["tooltip"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_row, {
          gutter: 10,
          style: {"margin-top":"10px"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, { span: 12 }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bugCardList, (i) => {
                      return (_openBlock(), _createBlock(_component_basic_card, {
                        key: i.key,
                        title: i.title,
                        tooltip: i.key === 1 ? _ctx.tooltip.unSolvedBug : i.key === 2 ? _ctx.tooltip.checkBug : '',
                        height: 125,
                        class: "board-small-card"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", _hoisted_8, _toDisplayString(i.count), 1)
                        ]),
                        _: 2
                      }, 1032, ["title", "tooltip"]))
                    }), 128))
                  ]),
                  _createElementVNode("div", _hoisted_9, [
                    _createVNode(_component_basic_card, {
                      title: "BUG状态分布图",
                      height: 400
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_pie_chart, {
                          id: "pie2",
                          type: 1,
                          data: _ctx.bugStatusPie
                        }, null, 8, ["data"])
                      ]),
                      _: 1
                    })
                  ])
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 12 }, {
              default: _withCtx(() => [
                _createVNode(_component_basic_card, {
                  title: "BUG燃尽图",
                  height: 400,
                  tooltip: _ctx.tooltip.iterationBugCountBurnDownLine
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_line_chart, {
                      id: "line3",
                      type: 2,
                      data: _ctx.iterationBugCountBurnDownLine
                    }, null, 8, ["data"])
                  ]),
                  _: 1
                }, 8, ["tooltip"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_row, {
          gutter: 10,
          style: {"margin-top":"10px"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, { span: 12 }, {
              default: _withCtx(() => [
                _createVNode(_component_basic_card, {
                  title: "成员BUG数",
                  height: 400
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_bar_chart, {
                      id: "bar2",
                      type: 2,
                      data: _ctx.staffBugCountBar
                    }, null, 8, ["data"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 12 }, {
              default: _withCtx(() => [
                _createVNode(_component_basic_card, {
                  title: "成员BUG响应/存活时长",
                  height: 400,
                  tooltip: _ctx.tooltip.staffBugTimeDurationBar
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_bar_chart, {
                      id: "bar3",
                      type: 1,
                      data: _ctx.staffBugTimeDurationBar
                    }, null, 8, ["data"])
                  ]),
                  _: 1
                }, 8, ["tooltip"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_10, [
        _createVNode(_component_el_empty)
      ]))
}